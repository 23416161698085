import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import { StyleSheet } from 'aphrodite'
import Button from './common/Button'
import { PRODUCT_DETAIL } from '../urls'
import { useDeleteRequest } from '../hooks/request'
import { useModal } from '../hooks/modal'
import ProductUpdate from './ProductUpdate'
import { format } from '../utils/number'
import { measurement } from '../utils/position'
import PrintBarCode from './PrintBarCode'
import ProductHistory from './ProductHistory'
import { getCurrency } from '../utils/types'
import { getDateTime } from '../utils/date'

export default function ProductHomeItem({
    check,
    categoryList,
    item,
    user,
    brandList,
    index,
    products,
}) {
    const [showDetailModal, hideDetailModal] = useModal(
        <ProductHistory
            check={check}
            productId={item.id}
            user={user}
            item={item}
            hideModal={() => hideDetailModal()}
        />, styles.modal,
    )

    const productRemove = useDeleteRequest({ url: PRODUCT_DETAIL.replace('{id}', item?.id) })

    async function onDelete() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            await productRemove.request()
            await products.request()
        }
    }

    const [showUpdateModal, setShowUpdateModal] = useModal(
        <ProductUpdate
            products={products}
            categoryList={categoryList}
            brandList={brandList}
            item={item}
            hideModal={() => setShowUpdateModal()}
        />,
        styles.modal,
    )

    const componentRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })

    return (
        <tr className=" is-size-6" key={item.id}>
            <td className="is-size-6">{index + 1}</td>
            <td onClick={() => showDetailModal()} className="is-size-6 is-capitalized">{item?.name}</td>
            <td className="is-size-6">{item?.count} {measurement(item?.measurement)}</td>
            <td className="is-size-6">{format(item?.priceSale)} {getCurrency(item.paymentType)}

                {item.usdPriceSale
                    ? (
                        <span
                            className="tag is-small is-link is-light is-size-6">{`${format(item.usdPriceSale)}Сум`}
                        </span>
                    )
                    : null}
            </td>
            <td className="is-size-6">{format(item?.price)} {getCurrency(item.paymentType)}

                {item.usdPrice
                    ? (
                        <span
                            className="tag is-small is-link is-light is-size-6">{`${format(item.usdPrice)}Сум`}
                        </span>
                    )
                    : null}
            </td>
            <td className="is-size-6">{format(item?.priceTransfer)} {getCurrency(item.paymentType)}

                {item.usdPriceTransfer
                    ? (
                        <span
                            className="tag is-small is-link is-light is-size-6">{`${format(item.usdPriceTransfer)}Сум`}
                        </span>
                    )
                    : null}
            </td>
            <td className="is-size-6">{getDateTime(item.createdAt)}</td>

            <td className="">
                <div className="is-pulled-right">
                    <Button
                        onClick={handlePrint}
                        className="mr-2 button is-link is-outlined is-small"
                        icon="qr-code-outline" />
                    <div style={{ display: 'none' }}>
                        <PrintBarCode item={item} ref={componentRef} />
                    </div>
                    <Button
                        onClick={showUpdateModal}
                        className="mr-2 button is-success is-outlined is-small"
                        icon="pencil-outline" />
                    <Button
                        onClick={onDelete}
                        className="button is-danger is-outlined is-small"
                        icon="trash" />
                </div>

            </td>
        </tr>
    )
}

const styles = StyleSheet.create({
    tr: {
        ':nth-child(1n) > td': {
            lineHeight: 1,
        },
    },
    center: {
        display: 'flex',
        alignItems: 'center',
        // height: '100%',
    },

    td1: {
        display: 'flex',
        alignItems: 'center',
        gap: '5px',
        lineHeight: 1,
        cursor: 'pointer',
    },
    td1Img: {
        width: 32,
        height: 32,
        borderRadius: '50%',
        objectFit: 'cover',
    },
    td1Right: {

    },
    td1RightTitle: {
        fontFamily: 'GilroySemi',
        color: '#333333',
        lineHeight: '19px',
    },
    td1RightSubtitle: {
        marginTop: '1px',
        fontFamily: 'Gilroy',
        fontSize: '14px',
        color: 'rgba(51, 51, 51, 0.7)',
        lineHeight: '16px',
    },
    industries: {
        display: 'flex',
        whiteSpace: 'nowrap',
        flexWrap: 'wrap',
    },
    modal: {
        width: 1000,
    },
})
