import React from 'react'
import { PRODUCT_DETAIL } from '../urls'
import { usePutRequest } from '../hooks/request'
import ProductForm from './ProductForm'
import { useMessage } from '../hooks/message'

export default function ProductUpdate({ hideModal, item, brandList, products }) {
    const [showMessage] = useMessage()
    const productUpdate = usePutRequest({ url: PRODUCT_DETAIL.replace('{id}', item.id) })

    async function onSubmit(data) {
        const { success, error } = await productUpdate.request({ data: {
            ...data,
            usd_price: data.usd_price || undefined,
            usd_price_sale: data.usd_price_sale || undefined,
            usd_price_transfer: data.usd_price_transfer || undefined,
        } })
        if (success) {
            products.request()
            showMessage('Успешно обновлен', 'is-success')
            hideModal()
        }
        if (error) {
            showMessage(error?.data?.detail || 'Ошибка', 'is-danger')
        }
    }

    return (
        <div>
            <h1 className="title has-text-centered"><b>Изменить товар</b></h1>

            <ProductForm
                onCancel={hideModal}
                brandList={brandList}
                initialValues={{
                    ...item,
                }}
                onSubmit={onSubmit} />
        </div>
    )
}
