import React, { useCallback, useState } from 'react'
import { FieldArray, Form, Formik } from 'formik'
import { debounce } from 'lodash'
import Button from './common/Button'
import Input from './common/Input'
import Card from './common/Card'
import { format } from '../utils/number'
import { useLoad } from '../hooks/request'
import { PRODUCT_LIST, SUPPLIER_LIST } from '../urls'
import Select from './common/Select'
import { currency, paid, paymentMethod } from '../utils/types'
import Table from './common/Table'
import { required } from '../utils/validators'
import { useModal } from '../hooks/modal'
import ProductCreate from './ProductCreate'
import ProductUpdate from './ProductUpdate'

export default function DocumentsForm({ onCancel, onSubmit, initialValues = {} }) {
    const [params, setParams] = useState({})
    const productLoad = useLoad({ url: PRODUCT_LIST, params }, [params])
    const productItem = productLoad.response ? productLoad.response.results : []
    const products = productItem.map((item) => ({
        ...item,
        quantity: '',
        count: item?.count ?? '',
        price: item?.price ?? '',
        price_sale: item?.priceSale ?? '',
        price_transfer: item?.priceTransfer ?? '',
    }))
    const suppliers = useLoad({ url: SUPPLIER_LIST })

    const [showProductCreate, hideProductCreate] = useModal(
        <ProductCreate
            onCancel={() => { hideProductCreate() }}
            reload={productLoad}
        />,
    )
    const [showProductEdit, hideProductEdit] = useModal(
        (item) => (
            <ProductUpdate
                hideModal={() => { hideProductEdit() }}
                products={productLoad}
                item={item}
            />
        ),
    )

    const debouncedSearch = debounce((search) => {
        setParams((prev) => ({ ...prev, search }))
    }, 500)
    const handleSearch = useCallback((({ target: { value } }) => {
        debouncedSearch(value)
    }), [debouncedSearch])

    const handleSubmit = (vals, actions) => {
        const data = {
            ...vals,
            dateReturn: vals.dateReturn || undefined,
            invoice: vals.invoice ? +vals.invoice : undefined,
            wholesale: vals.wholesale ? +vals.wholesale : undefined,
            markup: vals.markup ? +vals.markup : undefined,
            vat: vals.vat ? +vals.vat : undefined,
            expenses: vals.expenses ? +vals.expenses : undefined,
        }
        onSubmit(data, actions)
    }

    return (
        <Formik
            enableReinitialize
            onSubmit={handleSubmit}
            initialValues={{
                products: [],
                supplier: '',
                dateReturn: '',
                currency: '',
                paid: '',
                paymentMethod: '',
                invoice: '',
                wholesale: '',
                markup: '',
                sales: '',
                margin: '',
                vat: '',
                note: '',
                expenses: '',
                ...initialValues,
            }}
        >
            {({ values, setFieldValue }) => {
                // Calculate totals
                const totals = values.products.reduce(
                    (acc, product) => {
                        const quantity = +product.quantity || 0
                        const price = typeof product.price === 'number' ? product.price : 0
                        const priceSale = typeof product.price_sale === 'number' ? product.price_sale : 0

                        acc.totalQuantity += quantity
                        acc.totalPurchaseSum += quantity * price
                        acc.totalSaleSum += quantity * priceSale

                        return acc
                    },
                    {
                        totalQuantity: 0,
                        totalPurchaseSum: 0,
                        totalSaleSum: 0,
                    },
                )
                return (
                    <Form>
                        <Card>
                            <div className="columns">
                                <div className="column">
                                    <Input
                                        disabled
                                        placeholder="BekStroyMarket"
                                        name="name"
                                        label="Торговая точка*"
                                    />
                                </div>

                                <div className="column">
                                    <Select
                                        validate={required}
                                        empty
                                        options={suppliers.response ? suppliers.response.results : []}
                                        name="supplier"
                                        label="Поставщик*"
                                    />
                                </div>
                                <div className="column">
                                    <Input
                                        name="dateReturn"
                                        label="Дата возврата"
                                        type="number"
                                    />
                                </div>
                                <div className="column">
                                    <Select
                                        options={currency}
                                        optionValue="value"
                                        name="currency"
                                        label="Валюта*"
                                        onChange={(e) => {
                                            setFieldValue('currency', e.target.value)
                                            setFieldValue('products', [])
                                        }}
                                    />
                                </div>

                                <div className="column">
                                    <Select
                                        options={paid}
                                        optionValue="value"
                                        name="paid"
                                        label="Оплачено*"
                                    />
                                </div>
                                <div className="column">
                                    <Select
                                        options={paymentMethod}
                                        optionValue="value"
                                        name="paymentMethod"
                                        label="Способ оплаты*"
                                    />
                                </div>
                            </div>

                            <div className="columns">
                                <div className="column">
                                    <Input
                                        step="0.01"
                                        type="number"
                                        name="invoice"
                                        label="№ Накладной"
                                    />
                                </div>

                                <div className="column">
                                    <Input
                                        step="0.01"
                                        type="number"
                                        name="wholesale"
                                        label="Наценка оптовая"
                                    />
                                </div>
                                <div className="column">
                                    <Input
                                        step="0.01"
                                        type="number"
                                        name="markup"
                                        label="Наценка продажная"
                                    />
                                </div>

                                <div className="column">
                                    <Input
                                        step="0.01"
                                        type="number"
                                        name="vat"
                                        label="НДС"
                                    />
                                </div>

                                <div className="column">
                                    <Input
                                        name="note"
                                        label="Примечание"
                                    />
                                </div>
                                <div className="column">
                                    <Input
                                        step="0.01"
                                        type="number"
                                        name="expenses"
                                        label="Расходы"
                                    />
                                </div>
                            </div>
                        </Card>
                        <br />

                        <Card>

                            <FieldArray
                                name="products"
                                render={(arrayHelpers) => (
                                    <>
                                        <Table
                                            showEmptyMessage={false}
                                            columns={{
                                                name: 'Наименование товара',
                                                code: 'Штрих-код',
                                                remains: 'Остаток',
                                                quantity: 'Количество',
                                                price: 'Цена поступление\t',
                                                price_transfer: 'Оптом цена\t',
                                                price_sale: 'Цена за единицу\t',
                                                summ: 'Сумма',
                                                actions: '',
                                            }}
                                            items={values.products}
                                            renderItem={(item, index) => {
                                                const quantity = +values.products?.[index].quantity || 0
                                                const price = typeof item?.price === 'number' ? item?.price : 0
                                                const sum = format(quantity * price)

                                                return (
                                                    <tr key={item?.id}>
                                                        <td>{item.name}</td>
                                                        <td>{item.codeContent}</td>
                                                        <td>{item.count}</td>
                                                        <td>
                                                            <Input validate={required} type="number" className="is-size-7"
                                                                name={`products[${index}].quantity`} />
                                                        </td>
                                                        <td>
                                                            <Input type="number" className="is-size-7"
                                                                name={`products[${index}].price`} />
                                                        </td>
                                                        <td>
                                                            <Input type="number" className="is-size-7"
                                                                name={`products.${index}.price_transfer`} />
                                                        </td>
                                                        <td>
                                                            <Input type="number" className="is-size-7"
                                                                name={`products.${index}.price_sale`} />
                                                        </td>
                                                        <td>{sum ?? ''}</td>
                                                        <td>
                                                            <Button
                                                                onClick={() => arrayHelpers.remove(index)}
                                                                icon="close-outline"
                                                                className="is-danger is-outlined is-size-7" />
                                                        </td>
                                                    </tr>
                                                )
                                            }}
                                        />

                                        <div className="is-flex mt-6">
                                            <input type="search" className="input" onChange={handleSearch} />

                                            <div className="ml-2">
                                                <Button
                                                    onClick={showProductCreate}
                                                    icon="add-outline"
                                                    className="is-outlined" />&nbsp;&nbsp;
                                            </div>
                                        </div>

                                        {params.search && (
                                            <Table
                                                columns={{
                                                    name: 'Наименование товара',
                                                    code: 'Штрих-код',
                                                    quantity: 'Количество',
                                                    actions: '',
                                                }}
                                                items={products.filter((prod) => !values.products.map((p) => p?.id).includes(prod?.id))}
                                                renderItem={(item) => (
                                                    <tr key={item?.id}>
                                                        <td>{item?.name}</td>
                                                        <td>{item?.codeContent}</td>
                                                        <td>{item?.count} {item?.measurement}</td>
                                                        <td style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                                                            <Button
                                                                onClick={() => {
                                                                    showProductEdit(item)
                                                                }}
                                                                icon="create-outline"
                                                                className="is-size-7" />
                                                            {item?.paymentType === values.currency
                                                                ? (
                                                                    <Button
                                                                        onClick={() => { arrayHelpers.push(item) }}
                                                                        icon="add-outline"
                                                                        className="is-size-7" />
                                                                ) : (
                                                                    <span className="has-text-danger">
                                                                        Выберите другую валюту для добавления
                                                                    </span>
                                                                )}
                                                        </td>
                                                    </tr>
                                                )}
                                            />
                                        )}
                                    </>
                                )}
                            />

                            <div className="my-4">
                                <div className="is-pulled-left">
                                    <span className="tag is-large is-link is-light mr-2">
                                        Общее количество: {totals.totalQuantity.toFixed(2)}
                                    </span>
                                    <span className="tag is-large is-link is-light mr-2">
                                        Сумма прихода: {totals.totalPurchaseSum.toFixed(2)}
                                    </span>
                                    <span className="tag is-large is-link is-light">
                                        Сумма продаж: {totals.totalSaleSum.toFixed(2)}
                                    </span>
                                </div>
                                <div className="is-pulled-right">
                                    <Button
                                        onClick={onCancel}
                                        icon="close-outline"
                                        text="Отмена"
                                        className="is-danger" />&nbsp;&nbsp;

                                    <Button
                                        text="Сохранить"
                                        icon="add-outline"
                                        type="submit"
                                        className="is-success" />
                                </div>
                            </div>

                            <br />
                        </Card>
                    </Form>
                )
            }}
        </Formik>
    )
}
