import React from 'react'
import { useParams } from 'react-router-dom'
import { useQueryParams } from '../hooks/queryString'
import { useLoad } from '../hooks/request'
import { useModal } from '../hooks/modal'
import { SUPPLIER_DETAIL, SUPPLIER_ITEM_LIST } from '../urls'
import Layout from '../components/Layout'
import Button from '../components/common/Button'
import SupplierItemCreate from '../components/SupplierItemCreate'
import SupplierItemItem from '../components/SupplierItemItem'
import SupplierTabs from '../components/SupplierTabs'
import { format } from '../utils/number'
import NewTable from '../components/common/NewTable'

export default function SupplierChecksDetail() {
    const { supplierId } = useParams()
    const params = useQueryParams()
    const classDetail = useLoad({ url: SUPPLIER_DETAIL.replace('{id}', supplierId) })
    const className = classDetail.response
    const supplierItems = useLoad({
        url: SUPPLIER_ITEM_LIST,
        params: { supplier: supplierId, ...params },
    })

    const [showCreateModal, hideCreateModal] = useModal(
        <SupplierItemCreate
            groupName={className ? className.name : ''}
            supplierId={supplierId}
            onSuccess={() => {
                supplierItems.request()
                hideCreateModal()
            }} onCancel={() => hideCreateModal()} />,
    )

    return (
        <Layout>
            <SupplierTabs supplierId={supplierId} name={className ? className.name : ''} />

            <Button
                onClick={showCreateModal}
                text="Добавить"
                icon="add-outline"
                className="is-link is-outlined is-pulled-right" />
            <br />
            <NewTable
                loading={supplierItems.loading}
                items={supplierItems.response ? supplierItems.response.results : []}
                columns={{
                    id: '№',
                    name: 'Способ оплаты\t',
                    phone: 'Примечание',
                    phone_director: 'Дата\t',
                    // phone_accountant: 'Дата возврата\t',
                    currency: 'Валюта',
                    come: 'Приход',
                    buttons: 'Расход',
                    actions: '',
                }}
                totalCount={supplierItems.response ? supplierItems.response.count : 0}
                pageSize={15}
                renderItem={(item, index) => (
                    <SupplierItemItem
                        groupName={className ? className.name : ''}
                        index={index}
                        key={item.id}
                        item={item}
                        supplierItems={supplierItems} />
                )} />

            {!supplierItems.loading ? (
                <>
                    <hr />
                    <div className="columns is-flex mb-2">
                        <div className="column">
                            <p className="is-size-5 tag is-link is-light">
                                <span className="mr-2">Приход USD:</span>
                                <span className="">
                                    {format(className ? className.usdComingTotal : 0)} $
                                </span>
                            </p>
                        </div>
                        <div className="column">
                            <p className="is-size-5 tag is-link is-light">
                                <span className="mr-2">Приход Сум:</span>
                                <span className="">
                                    {format(className ? className.sumComingTotal : 0)} сум
                                </span>
                            </p>
                        </div>
                    </div>
                    <div className="columns">

                        <div className="column">
                            <p className="is-size-5 tag is-link is-light">
                                <span className="mr-2">Расход USD:</span>
                                <span className="">
                                    {format(className ? className.usdConsumptionTotal : 0)} $
                                </span>
                            </p>
                        </div>
                        <div className="column">
                            <p className="is-size-5 tag is-link is-light">
                                <span className="mr-2">Расход Сум:</span>
                                <span className="">
                                    {format(className ? className.sumConsumptionTotal : 0)} сум
                                </span>
                            </p>
                        </div>

                    </div>
                </>
            ) : null}

        </Layout>
    )
}
