import React, { useEffect } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import NotFound from './pages/NotFound'
import { getEnv } from './utils/environment'
import BaseContextWrapper from './components/common/BaseContext'
import { LanguageProvider } from './contexts/LanguageContext'
import Login from './pages/Login'
import ProtectedRoute from './components/common/ProtectedRoute'
import Borrow from './pages/Borrow'
import Categories from './pages/Categories'
import CategoryDetail from './pages/CategoryDetail'
import Products from './pages/Products'
import Archive from './pages/Archive'
import SettingsUser from './pages/settings/SettingsUser'
import Currency from './pages/settings/Currency'
import Bonus from './pages/Bonus'
import Home from './pages/Home'
import Supplier from './pages/Supplier'
import SupplierChecksDetail from './pages/SupplierChecksDetail'
import ClientOrders from './pages/ClientOrders'
import SupplierProductsDetail from './pages/SupplierProductsDetail'
import Documents from './pages/Documents'
import DocumentsCreate from './components/DocumentsCreate'
import DocumentsPreview from './pages/DocumentsPreview'

export default function App() {
    useEffect(() => {
        if (getEnv() === 'production') return
        document.title = `${getEnv().toUpperCase()} ${document.title}`
    }, [])

    return (
        <LanguageProvider>
            <BrowserRouter>
                <BaseContextWrapper>
                    <Switch>
                        <Route path="/" component={Login} exact />
                        <ProtectedRoute path="/home" component={Home} exact />
                        <ProtectedRoute path="/products" component={Products} exact />
                        <ProtectedRoute path="/supplier" component={Supplier} exact />
                        <ProtectedRoute path="/documents-in" component={Documents} exact />
                        <ProtectedRoute path="/documents-in/preview/:documentId" component={DocumentsPreview} exact />
                        <ProtectedRoute path="/documents-in/create" component={DocumentsCreate} exact />
                        <ProtectedRoute path="/supplier/:supplierId/checks" component={SupplierChecksDetail} exact />
                        <ProtectedRoute path="/supplier/:supplierId/products" component={SupplierProductsDetail} exact />
                        <ProtectedRoute path="/categories" component={Categories} exact />
                        <ProtectedRoute path="/category" component={CategoryDetail} exact />
                        <ProtectedRoute path="/borrow" component={Borrow} exact />
                        <ProtectedRoute path="/client" component={Bonus} exact />
                        <ProtectedRoute path="/client/:clientId/order" component={ClientOrders} exact />
                        <ProtectedRoute path="/archive/orders" component={Archive} exact />
                        <ProtectedRoute path="/settings/user" component={SettingsUser} exact />
                        <ProtectedRoute path="/settings/currency" component={Currency} exact />
                        <Route path="" component={NotFound} exact />
                    </Switch>
                </BaseContextWrapper>
            </BrowserRouter>
        </LanguageProvider>
    )
}
