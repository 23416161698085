import { isEmpty } from 'lodash'
import React, { forwardRef } from 'react'
import { format } from '../utils/number'
import { measurement } from '../utils/position'
import { getDateTime } from '../utils/date'

const ViewCheckArchive = forwardRef(({ item, result }, ref) => (
    <div ref={ref}>
        <div className="is-flex is-justify-content-center is-align-items-center mt-2">
            <div>
                <p className="is-italic mt-2 mb-2"><b>Покупатель</b>: {item.client.name} ({item.client.phoneNumber})</p>
            </div>
        </div>
        <div className="is-flex is-justify-content-center is-align-items-center">
            <table border={1} style={{ textAlign: 'center' }} className="table is-bordered is-align-items-center">
                <th style={{ border: '1px solid black' }}>Наименование</th>
                <th style={{ border: '1px solid black' }}>Ед. <br />изм</th>
                <th style={{ border: '1px solid black' }}>
                    Кол- <br />
                    во
                </th>
                <th style={{ border: '1px solid black' }}>Цена</th>
                <th style={{ border: '1px solid black' }}>Сумма</th>

                {item?.orderProduct.map((elem) => (
                    <tr>
                        <td style={{ border: '1px solid black' }}>{elem.product.name}</td>
                        <td style={{ border: '1px solid black' }}>{measurement(elem.product.measurement)}</td>
                        <td style={{ border: '1px solid black' }}>{elem.count}</td>
                        <td style={{ border: '1px solid black' }}>{format(elem.price)}</td>
                        <td style={{ border: '1px solid black' }}>{format(elem.count * elem.price)}</td>
                    </tr>
                ))}
                <tr>
                    <th style={{ border: '1px solid black' }}>Итого:</th>
                    <td style={{ textAlign: 'center', border: '1px solid black' }} colSpan={4}>{format(result)} сўм</td>
                </tr>
                <tr>
                    <td style={{ textAlign: 'center', border: '1px solid black' }} colSpan={5}>
                        {getDateTime(!isEmpty(item.orderHistory) ? (item?.orderHistory[0].createdAt) : item.createdAt)}
                    </td>
                </tr>
            </table>

        </div>
    </div>
))

export default ViewCheckArchive
