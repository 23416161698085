import React, { forwardRef } from 'react'
import { format } from '../utils/number'
import { getDateTime } from '../utils/date'
import { domain } from '../urls'

const PrintBarCode = forwardRef(({ item, result }, ref) => (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid black', width: 700, height: 100 }}>
        <div ref={ref}>
            <div className="is-flex is-justify-content-center is-align-items-center">
                <div>
                    <p className="is-bold is-size-2">Bek Stroy Market</p>
                </div>
            </div>
            <div className="is-flex is-justify-content-center is-align-items-center mt-2">
                <div>
                    <span className="is-size-4 mt-2"><b className="is-bold">Тел</b>: (99) 969-35-05</span>
                    <p className="is-size-4 mt-2"><b className="is-bold">Счет</b> - № {item.id} от {getDateTime(new Date())}</p>
                    <p className="is-size-4 mt-2"><b className="is-bold">Кассир</b>:  Ботир</p>
                    <p className="is-size-4 mt-2 mb-2"><b>Покупатель</b>: {item.client?.name} ({item.client?.phoneNumber})</p>
                </div>
            </div>
            <div className="is-flex is-justify-content-center is-align-items-center">
                <table style={{ textAlign: 'center' }} className="is-bordered is-align-items-center">
                    <thead>
                        <tr>
                            <th className="is-size-4 p-2" style={{ border: '1px solid black' }}>Наименование</th>
                            {/* <th className="is-size-4" style={{ border: '1px solid black' }}>Ед. <br />изм</th> */}
                            <th className="is-size-4 p-1" style={{ border: '1px solid black' }}>
                                Кол <br />
                                -во
                            </th>
                            <th className="is-size-4" style={{ border: '1px solid black' }}>Цена</th>
                            <th className="is-size-4" style={{ border: '1px solid black' }}>Сумма</th>
                        </tr>
                    </thead>

                    <tbody>
                        {item?.orderProduct.map((elem, i) => (
                            <tr key={elem?.id || i} className="is-capitalized">
                                <td className="p-1 is-size-4" style={{ border: '1px solid black', textAlign: 'left' }}>
                                    {elem.product.name}
                                </td>
                                {/* <td className="" style={{ border: '1px solid black' }}> */}
                                {/*     {measurement(elem.product.measurement)} */}
                                {/* </td> */}
                                <td className="p-1 is-size-4" style={{ border: '1px solid black' }}>{elem.count}</td>
                                <td className="p-1 is-size-4" style={{ border: '1px solid black' }}>
                                    {format(elem.price)}
                                </td>
                                <td className="p-1 is-size-4" style={{ border: '1px solid black' }}>
                                    {format(elem.price * elem.count)}
                                </td>
                            </tr>
                        ))}

                        <tr>
                            <th className="is-size-4" style={{ border: '1px solid black' }}>Долг:</th>
                            <td className="is-size-4" style={{ textAlign: 'center', border: '1px solid black' }} colSpan={4}>{0} Сум</td>
                        </tr>
                        <tr>
                            <th className="is-size-4" style={{ border: '1px solid black' }}>Итого:</th>
                            <td className="is-size-4" style={{ textAlign: 'center', border: '1px solid black' }} colSpan={4}>
                                {format(result)} Сум
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
            <div className="is-flex is-justify-content-center is-align-items-center mt-2">
                <div style={{
                    width: 300,
                    height: 100,
                }}>
                    <img src={domain + item.barcode} alt="" />
                </div>
            </div>
            <div className="is-flex is-justify-content-center is-align-items-center mt-6 mb-6">
                <p className="is-size-2">Спасибо за покупку</p>
            </div>
        </div>
    </div>
))

export default PrintBarCode
