import React from 'react'
import { Form, Formik } from 'formik'
import Button from './common/Button'
import { required } from '../utils/validators'
import Input from './common/Input'
import Select from './common/Select'
import { currency, payment, pricedType } from '../utils/types'

export default function SupplierItemForm({ onCancel, onSubmit, initialValues = {} }) {
    return (
        <Formik
            onSubmit={onSubmit}
            initialValues={{
                reasonablyPriced: '',
                paymentType: '',
                payment: '',
                amount: '',
                comment: '',
                supplier: '',
                ...initialValues,
            }}>
            <Form>
                <Select
                    optionValue="value"
                    optionLabel="name"
                    empty
                    options={pricedType}
                    name="reasonablyPriced"
                    label="Способ оплаты"
                />
                <Select
                    optionValue="value"
                    optionLabel="name"
                    empty
                    options={currency}
                    name="payment"
                    label="Выберите тип оплаты"
                />
                <Select
                    optionValue="value"
                    optionLabel="name"
                    empty
                    options={payment}
                    name="paymentType"
                    label="Выберите тип оплаты"
                />

                <Input
                    name="amount"
                    label="Сумма*"
                    validate={required} />

                <Input
                    component="textarea"
                    name="comment"
                    label="Примечание"
                />

                <div className="is-pulled-right">
                    <Button
                        onClick={onCancel}
                        icon="close-outline"
                        text="Отмена"
                        className="is-danger" />&nbsp;&nbsp;

                    <Button
                        text="Сохранить"
                        icon="add-outline"
                        type="submit"
                        className="is-success" />
                </div>
            </Form>
        </Formik>
    )
}
