import React, { useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import Card from './common/Card'
import { getDateTime } from '../utils/date'
import { format } from '../utils/number'
import Button from './common/Button'
import ComponentToPrint from './Print'

export default function ClientOrderItem({ item }) {
    // eslint-disable-next-line max-len,no-return-assign
    const result = item.orderProduct.reduce((total, currentValue) => total + parseInt(currentValue.price * currentValue.count, 10), 0)
    const componentRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    })
    return (
        <Card>
            <table style={{ textAlign: 'left', overflowX: 'hidden' }}
                className="table is-fullwidth is-bordered is-size-6">
                <thead>
                    <tr>
                        <th>Наименование</th>
                        <th>Количество</th>
                        <th>Цена</th>
                        <th>Сумма</th>
                    </tr>
                </thead>

                <tbody>
                    {item.orderProduct.map((product, index) => (
                        <tr key={product?.id || index}>
                            <td>
                                <span
                                    style={{ textAlign: 'left' }}
                                    className="tag is-small is-link is-light">
                                    {index + 1}
                                </span> {product.product.name}
                            </td>
                            <td>{product.count}</td>
                            <td>{product.price}</td>
                            <td>{product.count * product.price}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div
                className="mt-2 is-pulled-right">
                <Button
                    onClick={handlePrint}
                    className="button is-link mr-2 is-outlined is-small ml-2"
                    icon="print" />
                <div style={{ display: 'none' }}>
                    <ComponentToPrint result={item.totalForOrderHistories} item={item} ref={componentRef} />
                </div>
                <span className="tag is-medium is-link is-light mb-3">Общая сумма: {format(result)} Сум</span>
                <span className="ml-2">{getDateTime(item.createdAt)}</span>

            </div>

        </Card>
    )
}
