import React from 'react'
import { isEmpty } from 'lodash'
import queryString from 'querystring'
import { useHistory } from 'react-router-dom'
import Layout from '../components/Layout'
import Table from '../components/common/Table'
import { useLoad } from '../hooks/request'
import { SUPPLIER_LIST } from '../urls'
import { useModal } from '../hooks/modal'
import { useQueryParams } from '../hooks/queryString'
import SupplierCreate from '../components/SupplierCreate'
import SupplierItem from '../components/SupplierItem'
import Search from '../components/Search'
import Button from '../components/common/Button'

export default function Supplier() {
    const params = useQueryParams()
    const suppliers = useLoad({ url: SUPPLIER_LIST, params: { ...params } })

    const [showCreateModal, setShowCreateModal] = useModal(
        <SupplierCreate
            suppliers={suppliers}
            onCancel={() => setShowCreateModal()} />,
    )
    const history = useHistory()

    function onSearch(query) {
        const search = !isEmpty(query) ? query : undefined
        history.push(`?${queryString.stringify({ ...params, search })}`)
    }

    return (
        <Layout>

            <div className="is-flex mb-3">
                <Search onSearch={onSearch} />

                <Button
                    // onClick={excelProduct.request}
                    icon="cloud-upload-outline"
                    className="button is-link is-outlined ml-2 mr-2"
                    text="Экспорт" />

                <label style={{ position: 'relative' }}>
                    <Button
                        icon="cloud-download-outline"
                        className="button is-link is-outlined ml-2 mr-2"
                        text="Импорт" />
                    {/* eslint-disable-next-line max-len */}
                    <input value="" onChange={() => {}} type="file" style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        opacity: 0,
                    }} />
                </label>

                <Button
                    text="Добавить"
                    icon="add-outline"
                    className="button is-link is-outlined ml-2"
                    onClick={showCreateModal} />

            </div>

            <Table
                loading={suppliers.loading}
                items={suppliers.response ? suppliers.response.results : []}
                columns={{
                    id: '№',
                    name: 'Имя',
                    phone: 'Телефон',
                    phone_director: 'Телефон директора',
                    phone_accountant: 'Телефон бугалтера',
                    buttons: '',
                }}
                totalCount={suppliers.response ? suppliers.response.count : 0}
                pageSize={15}
                renderItem={(item, index) => (
                    <SupplierItem
                        index={index}
                        key={item.id}
                        item={item}
                        suppliers={suppliers} />
                )} />
        </Layout>
    )
}
