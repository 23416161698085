import React from 'react'
import { NavLink } from 'react-router-dom'
import Button from './common/Button'
import { useModal } from '../hooks/modal'
import SupplierUpdate from './SupplierUpdate'
import { useDeleteRequest } from '../hooks/request'
import { SUPPLIER_DETAIL } from '../urls'

export default function SupplierItem({ item, userList, territory, index, suppliers }) {
    const [showUpdateModal, setShowUpdateModal] = useModal(
        <SupplierUpdate
            suppliers={suppliers}
            userList={userList}
            item={item}
            hideModal={() => setShowUpdateModal()}
            territory={territory}
        />,
    )

    const productRemove = useDeleteRequest({ url: SUPPLIER_DETAIL.replace('{id}', item.id) })

    async function onDelete() {
        if (global.confirm('Вы действительно хотите удалить?')) {
            await productRemove.request()
            suppliers.request()
        }
    }

    return (
        <tr key={item.id}>
            <td className="is-size-5">{index + 1}</td>

            <td className="is-size-5">
                <NavLink to={`/supplier/${item.id}/checks`}>
                    {item.name}
                </NavLink>
            </td>

            <td className="is-size-5">{item.phone}</td>
            <td className="is-size-5">{item.phoneDirector}</td>
            <td className="is-size-5">{item.phoneAccountant} </td>
            <td className="">

                <div className="is-pulled-right">
                    <Button
                        onClick={showUpdateModal}
                        className="mr-2 button is-success is-outlined"
                        icon="pencil-outline" />
                    <Button
                        onClick={onDelete}
                        className="button is-danger mr-2 is-outlined"
                        icon="trash" />
                </div>

            </td>

        </tr>
    )
}
